.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #c04646;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}
