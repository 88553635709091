.game-container:hover {
  animation: bounce 2.5s infinite;
  -webkit-animation: bounce 2.5s infinite; /* Safari and Chrome */
  -moz-animation: bounce 2.5s infinite; /* Firefox */
  -o-animation: bounce 2.5s infinite; /* Opera */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}
