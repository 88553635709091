body {
  margin: 0;
  font-family: "Newsreader", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typewriter-effect {
  position: relative;
  color: hsl(0, 0%, 68%);
  overflow: hidden;
  border-right: 2px solid hsl(0, 0%, 80%);
  white-space: pre-wrap;
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms infinite;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: hsl(0, 0%, 80%);
  }
  to {
    border-right-color: transparent;
  }
}
/*  */

.text-outline-wrapper {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  width: fit-content;
  max-width: 100px;
  height: fit-content;
  border: 2px solid rgb(134, 133, 133);
  margin-top: 10px;
  margin-left: 10px;
  background: white;
  text-align: left;
}

/* Text outlined */
.text-outlined {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  color: white;
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
}

/* Quote */
blockquote {
  border-left: solid 4px #c9c9c9;
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

blockquote {
  quotes: none;
}
